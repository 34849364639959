const environment = {
	bontu: {
		domain: 'https://api.bontu.mx',
		apiKey: 'pWcema8rh958Uf0PxbBWg8YzszkxuzGU1BJrP26t'
	},
	production: true,
	hmr: false
};

export default environment;
